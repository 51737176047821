import { clsx } from 'clsx';
import React, { useState, useEffect, PropsWithChildren } from 'react';
import Surface from '../Surface';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
interface ILoaderProps {
  delay: number;
  text?: boolean;
  type?: 'primary' | 'secondary';
}

const Loader = ({
  delay,
  text,
  children,
  type = 'primary',
}: PropsWithChildren<ILoaderProps>) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);

  const renderText = () => {
    return text && <h2 className={styles.header}>{t('smile')}</h2>;
  };

  const renderSpinner = () => {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}>
          {[...Array(8)].map((el, i) => (
            <div key={i} className={styles[`bar${i + 1}`]} />
          ))}
        </div>
        <div className={styles.percent}>{percentage} %</div>
      </div>
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prev) => (prev >= 99 ? 99 : prev + 1));
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [percentage]);

  return (
    <Surface>
      <div className={clsx(styles.wrapper, styles[type])}>
        <div>
          {type === 'primary' ? (
            <>
              {renderSpinner()}
              {renderText()}
            </>
          ) : (
            <>
              {renderText()}
              {renderSpinner()}
            </>
          )}
        </div>
        {children}
      </div>
    </Surface>
  );
};

export default Loader;
