import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { FLOW_TYPES } from '../../constants/flow';

import styles from './styles.module.scss';
import { PhotoContext } from '../../context/PhotoProvider';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';
import Block from '../../components/Block';
import { Colors } from '../../constants/colorMap';
import { Button } from '../../components';
import { ProductTypes } from '../../types/enums';
import api from '../../services/api';
import { SizeInfo } from '../Instruction';
import { API_ROUTES } from '../../constants/api_routes';

type SizeType = {
  size: string;
  color: Colors;
  value: string;
  aspect: number;
};

const routesMap = {
  [ProductTypes.PAINTING]: {
    [FLOW_TYPES.PURCHASED]: {
      next: APP_ROUTES.UPLOAD_PAINTING_PHOTO,
      back: APP_ROUTES.PAINTING,
    },
    [FLOW_TYPES.DEMO]: {
      next: APP_ROUTES.UPLOAD_PAINTING_PHOTO,
      back: APP_ROUTES.PAINTING,
    },
    parent: APP_ROUTES.PAINTING,
  },
  [ProductTypes.MOSAIC]: {
    [FLOW_TYPES.PURCHASED]: {
      next: APP_ROUTES.CHOOSE_STYLE,
      back: APP_ROUTES.INSTRUCTION,
    },
    [FLOW_TYPES.DEMO]: {
      next: APP_ROUTES.HOW_TO_CHOOSE_PHOTO,
      back: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
    },
    parent: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
  },
  [ProductTypes.EMBROIDERY]: {
    [FLOW_TYPES.PURCHASED]: {
      next: APP_ROUTES.HOW_TO_CHOOSE_EMBROIDERY_PHOTO,
      back: APP_ROUTES.EMBROIDERY,
    },
    [FLOW_TYPES.DEMO]: {
      next: APP_ROUTES.HOW_TO_CHOOSE_EMBROIDERY_PHOTO,
      back: APP_ROUTES.EMBROIDERY,
    },
    parent: APP_ROUTES.EMBROIDERY,
  },
  [ProductTypes.DOT_PAINTING]: {
    [FLOW_TYPES.PURCHASED]: {
      next: APP_ROUTES.CHOOSE_STYLE,
      back: APP_ROUTES.INSTRUCTION,
    },
    [FLOW_TYPES.DEMO]: {
      next: APP_ROUTES.HOW_TO_CHOOSE_DOT_PAINTING_PHOTO,
      back: APP_ROUTES.DOT_PAINTING,
    },
    parent: APP_ROUTES.DOT_PAINTING,
  },
};

const fetchSizesInfo = async (productType: ProductTypes) => {
  const sizesRaw: SizeInfo[] = await api({
    URL: API_ROUTES.INFO_SIZES,
    options: {
      method: 'POST',
      body: { type: productType },
    },
  });

  const colors = ['purple', 'pink', 'blue'];
  const sizes: {
    size: string;
    color: Colors;
    value: string;
    aspect: number;
  }[] = sizesRaw?.map((item: SizeInfo, index: number) => {
    return {
      size: item.label,
      color: colors[index] as Colors,
      value: item.size,
      aspect: item.aspect,
    };
  });

  return sizes;
};

const ChooseSizeDiamondPhotoMosaic = () => {
  const { t } = useTranslation();
  const { setSize, setAspect, flow } = useContext(PhotoContext);
  const navigate = useNavigate();
  const { productType } = useContext(PhotoContext);

  const handleClick = (size: string, aspect: number) => {
    if (!flow) return;

    setSize(size);
    setAspect(aspect);
    navigate(routesMap[productType][flow].next);
  };
  const navigateBack = () => {
    if (!flow) return;

    navigate(routesMap[productType][flow].back);
  };

  const [sizes, setSizes] = useState<SizeType[]>([]);
  useEffect(() => {
    const fetchMyData = async () => {
      const sizes = await fetchSizesInfo(productType);
      setSizes(sizes);
    };
    fetchMyData();
  }, [productType]);

  if (!flow) {
    return <Navigate to={routesMap[productType].parent} />;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('chooseTheSize')}</h1>
      {sizes.map((size, index) => {
        return (
          <Block
            key={index}
            color={size.color}
            text={<p className={styles.text}>{t('size')}</p>}
            description={
              <p className={styles.description}>
                <Trans i18nKey="cm" values={{ size: size.size }} />
              </p>
            }
            buttonText={t('choose')}
            buttonAction={() => handleClick(size.value, size.aspect)}
          />
        );
      })}
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default ChooseSizeDiamondPhotoMosaic;
