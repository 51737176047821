import { ProductTypes } from '../types/enums';

export const PHOTO_KIT = [
  {
    id: 1,
    art_type: 'ORIGINAL',
    name: 'Original',
    color: 'purple',
  },
  {
    id: 2,
    art_type: 'VINTAGE',
    name: 'Sepia',
    color: 'pink',
  },
  {
    id: 3,
    art_type: 'POP_ART',
    name: 'Pop Art',
    color: 'blue',
  },
];

export const EXTENDED_PHOTO_KIT = [
  {
    id: 1,
    art_type: 'ORIGINAL',
    name: 'Original',
    color: 'purple',
  },
  {
    id: 2,
    art_type: 'VINTAGE',
    name: 'Sepia',
    color: 'green',
  },
  {
    id: 3,
    art_type: 'POP_ART',
    name: 'Pop Art',
    color: 'blue',
  },
  {
    id: 4,
    art_type: 'MAX_COLOR',
    name: 'MaxColor',
    color: 'pink',
  },
];

export const PHOTO_STYLES = {
  [ProductTypes.MOSAIC]: {
    '21x30': PHOTO_KIT,
    '30x40': EXTENDED_PHOTO_KIT,
    '40x50': EXTENDED_PHOTO_KIT,
  },
  [ProductTypes.PAINTING]: {
    '21x30': PHOTO_KIT,
    '30x40': PHOTO_KIT,
    '40x50': PHOTO_KIT,
  },
  [ProductTypes.DOT_PAINTING]: {
    '21x30': PHOTO_KIT,
    '30x40': EXTENDED_PHOTO_KIT,
    '40x50': EXTENDED_PHOTO_KIT,
  },
  [ProductTypes.EMBROIDERY]: {
    '21x30': PHOTO_KIT,
    '30x40': PHOTO_KIT,
    '40x50': PHOTO_KIT,
  },
};
