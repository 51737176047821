import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { FLOW_TYPES } from '../../constants/flow';
import { CROP_DEFAULT } from '../../constants/photoEditor';
import { PhotoContext } from '../../context/PhotoProvider';
import getCroppedImg from '../../services/getCroppedImg';
import { uploadImg } from '../../services/uploadImg';
import { ICroppedArea } from '../../types/interfaces';
import Banner from '../Banner';
import EditorControlsContainer from './EditorControlsContainer';
import Button from '../Button';
import Loader from '../Loader';
import { blobToBase64 } from '../../services/blobToBase64';
import '../../App.scss';
import styles from './styles.module.scss';

interface IPhotoEditorProps {
  sendPhoto: (base: string | ArrayBuffer | null) => void;
  navigateBack: () => void;
  aspect: number;
}

const PhotoEditor = ({
  sendPhoto,
  navigateBack,
  aspect,
}: IPhotoEditorProps) => {
  const { t } = useTranslation();

  const { photo, setPhoto, code, flow, weeklyProduct } =
    useContext(PhotoContext);

  const [src, setSrc] = useState<any>(photo);
  const [crop, setCrop] = useState(CROP_DEFAULT);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<ICroppedArea | null>(null);

  useEffect(() => {
    if (photo) {
      setSrc(photo);
    }
  }, [photo]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadImg(e, setSrc);
  };

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: ICroppedArea) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const setCroppedImage = useCallback(async () => {
    if (src === null || croppedAreaPixels === null) {
      return;
    }
    try {
      const croppedImage = await getCroppedImg(
        src,
        croppedAreaPixels,
        rotation,
      );
      if (!croppedImage) {
        throw new Error('Failed to crop the image.');
      }
      const base = await blobToBase64(croppedImage);
      sendPhoto(base);
      setPhoto(base);
    } catch (error) {
      console.error('Error while cropping the image:', error);
    }
  }, [croppedAreaPixels, rotation, src]);

  const onNextClick = async () => {
    setLoader(true);
    await setCroppedImage();
  };

  const delay = code ? 400 : 100;

  return (
    <div className={styles.photoEditor}>
      {loader ? (
        <Loader
          delay={delay}
          text
          type={flow === FLOW_TYPES.PURCHASED ? 'secondary' : 'primary'}
        >
          {flow === FLOW_TYPES.PURCHASED && weeklyProduct && (
            <Banner
              article={weeklyProduct.article}
              imageUrl={weeklyProduct.image}
            />
          )}
        </Loader>
      ) : (
        <>
          <h1>{t('desiredArea')}</h1>
          {src !== null && (
            <div className={styles.photoEditor__container}>
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                classes={{
                  containerClassName: styles.containerClassName,
                  mediaClassName: styles.mediaClassName,
                  cropAreaClassName: styles.cropAreaClassName,
                }}
              />
            </div>
          )}
          <input
            ref={fileInputRef}
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
          />
          <EditorControlsContainer
            fileInputRef={fileInputRef}
            rotation={rotation}
            setRotation={setRotation}
            setZoom={setZoom}
          />
          <div className={styles.buttonContainer}>
            <Button
              variant="tertiary"
              text={t('back')}
              onClick={navigateBack}
              disabled={loader}
            />
            <Button
              text={t('next')}
              variant="secondary"
              onClick={onNextClick}
              disabled={loader || src === null}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PhotoEditor;
