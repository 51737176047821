export const colorMap = {
  white: 'rgba(255, 255, 255, 1)',
  wb: 'rgba(245, 60, 194, ',
  ozon: 'rgba(82, 193, 255, ',
  purple: 'rgba(186, 170, 255, ',
  blue: 'rgba(82, 193, 255, ',
  yellow: 'rgba(255, 237, 70, ',
  pink: 'rgba(255, 139, 181, ',
  orange: 'rgba(255, 158, 85, ',
  green: 'rgba(99, 244, 160, ',
};

export type Colors =
  | 'white'
  | 'wb'
  | 'ozon'
  | 'purple'
  | 'blue'
  | 'yellow'
  | 'pink'
  | 'green'
  | 'orange';
