import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from '../../components';
import CodeInput from '../../components/CodeInput';
import { APP_ROUTES } from '../../constants/appRoutes';
import api from '../../services/api';
import { API_ROUTES } from '../../constants/api_routes';
import { PhotoContext } from '../../context/PhotoProvider';
import '../../App.scss';

import styles from './styles.module.scss';
// import { PHOTO_KIT } from '../../constants/photoKit';
import Surface from '../../components/Surface';
import { ProductTypes } from '../../types/enums';

interface IEmailCheck {
  status: string;
  usage_count: number;
  size: string;
  aspect: number;
}

export interface SizeInfo {
  type: string;
  size: string;
  label: string;
  aspect: number;
}

const routesMap = {
  [ProductTypes.MOSAIC]: {
    parentPage: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
    nextPage: APP_ROUTES.CHOOSE_SIZE_DIAMOND_PHOTO_MOSAIC,
    checkCode: API_ROUTES.CHECK_CODE,
  },
  [ProductTypes.PAINTING]: {
    parentPage: APP_ROUTES.PAINTING,
    nextPage: APP_ROUTES.UPLOAD_PAINTING_PHOTO_WITH_CODE,
    checkCode: API_ROUTES.CHECK_PAINTING_CODE,
  },
  [ProductTypes.EMBROIDERY]: {
    parentPage: APP_ROUTES.EMBROIDERY,
    nextPage: APP_ROUTES.HOW_TO_CHOOSE_EMBROIDERY_PHOTO,
    checkCode: API_ROUTES.CHECK_EMBROIDERY_CODE,
  },
  [ProductTypes.DOT_PAINTING]: {
    parentPage: APP_ROUTES.DOT_PAINTING,
    nextPage: APP_ROUTES.CHOOSE_SIZE_DOT_PAINTING,
    checkCode: API_ROUTES.CHECK_DOT_PAINTING_CODE,
  },
};

const Instruction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSize, setAspect, setCode /*, setStyleVariant*/, productType } =
    useContext(PhotoContext);

  const [values, setValues] = useState<string[]>(Array(9).fill(''));
  const [errorText, setErrorText] = useState<string | null>(null);

  const inputRefs = Array.from({ length: 9 }, () =>
    useRef<HTMLInputElement>(null),
  );

  useEffect(() => {
    if (inputRefs[0] && inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, []);

  const navigateBack = () => {
    navigate(routesMap[productType].parentPage);
  };

  const navigateNext = (/*code: string*/) => {
    // const firstLetter = code[0].toLowerCase();

    // if (firstLetter === 'o' || firstLetter === 'd') {
    //   setStyleVariant(PHOTO_KIT[0].id);
    //   return navigate(APP_ROUTES.UPLOAD_PHOTO_WITH_CODE);
    // }
    //
    // if (firstLetter === 'w' || firstLetter === 'p') {
    //   setStyleVariant(PHOTO_KIT[2].id);
    //   return navigate(APP_ROUTES.UPLOAD_PHOTO_WITH_CODE);
    // }
    //
    // if (firstLetter === 'v') {
    //   setStyleVariant(PHOTO_KIT[1].id);
    //   return navigate(APP_ROUTES.UPLOAD_PHOTO_WITH_CODE);
    // }

    const props = {
      navigateToEmail: true,
    };

    navigate(routesMap[productType].nextPage, { state: props });
  };

  const sendCode = async () => {
    const code = values?.join('');
    try {
      const emailCheck: IEmailCheck = await api({
        URL: routesMap[productType].checkCode,
        options: {
          method: 'POST',
          body: { code: code },
        },
      });
      if (emailCheck.status === 'usage limit exceeded') {
        setErrorText(t('amountOfAttempsIsBigger'));
      } else if (emailCheck.status !== 'exists') {
        setErrorText(t('enteredCodeIsNotFound'));
      } else {
        setCode(code);
        setSize(emailCheck.size);
        setAspect(emailCheck.aspect);
        navigateNext(/*code*/);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const codeValid = () => {
    const resCode = values.join('');
    return (
      values.length === 9 &&
      resCode !== '' &&
      values.every((value) => value !== '')
    );
  };

  return (
    <div
      className={
        errorText
          ? `${styles.errorContainer}`
          : `${styles.instructionConatainer}`
      }
    >
      <Surface>
        <h1>{t('enter9digitCodeFromInstruction')}</h1>

        <div>
          {values.map((value: string, index: number) => (
            <CodeInput
              key={index}
              index={index}
              values={values}
              setValues={setValues}
              value={value}
              inputRefs={inputRefs}
              setErrorText={setErrorText}
            />
          ))}
        </div>
        {errorText && <h2 className={styles.errorText}>{errorText}</h2>}
        <div className={styles.buttonsContainer}>
          <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
          <Button
            text={t('next')}
            variant="secondary"
            disabled={!codeValid() || errorText !== null}
            onClick={sendCode}
          />
        </div>
      </Surface>
    </div>
  );
};
export default Instruction;
