import { useEffect, useState } from 'react';

interface IWindowSize {
  width: number;
  height: number;
}

export const useWindowDimensions = () => {
  const [size, setSize] = useState<IWindowSize>({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    size,
    isMobileWidth: size.width <= 780,
    // isTabletWidth: size.width >= 768 && size.width < 1128,
    // isDesktopWidth: size.width >= 1128,
  };
};
